.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px; }
  @media (min-width: 600px) {
    .wrapper {
      padding: 0; } }
  .wrapper.padded {
    padding: 0 40px; }

@media (max-width: 600px) {
  .hide-xs {
    display: none; } }

.transition-300 {
  transition: all 300ms ease; }

.auth-view {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1BA9E0; }
  .auth-view .fader {
    z-index: 1; }
  .auth-view .auth-card {
    position: relative;
    z-index: 2;
    min-width: 350px; }
    .auth-view .auth-card .main-title {
      font-weight: 300;
      text-align: center;
      opacity: 0.6;
      letter-spacing: 1px; }
    .auth-view .auth-card .submit-btn {
      width: 100%; }
      .auth-view .auth-card .submit-btn:not(.facebook-btn) {
        margin-top: 30px; }
    .auth-view .auth-card .under-row {
      position: absolute;
      bottom: -25px;
      width: 100%;
      text-align: center; }
      .auth-view .auth-card .under-row a {
        text-decoration: none;
        color: white; }
    .auth-view .auth-card .facebook-login-btn {
      width: 100%; }

.dropzone-wrapper {
  margin-bottom: 30px;
  cursor: pointer; }
  .dropzone-wrapper > div {
    border-radius: 300px !important; }

@media (min-width: 600px) {
  .row-gt-xs.profile {
    justify-content: center;
    align-items: flex-end; } }

.row-gt-xs.profile .auth-card {
  margin-bottom: 30px; }
